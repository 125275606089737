import api from "@/utils/api";

const url = {
  brandClassify: "/hm/hmGoodsBrand/getByClassify", // 品牌分类 根据分类分组获取 (树形结构)
  brandAllClassify: "/hm/hmGoodsBrand/getAll", // 品牌分类 所有分类
  communityUrl: "/hm/serviceStaff/propertyCommunitySelect", // 绑定物业下的社区所有选项
}
const state = {
  brandClassify: null,
  brandAllClassify: null,
  community: null,
}

const getters = {
  // 品牌分类 根据分类分组获取
  brandClassifyOption: state => {
    return state.brandClassify
  },
  // 品牌分类 所有分类
  brandAllClassifyOption: state => {
    return state.brandAllClassify
  },
  // 绑定物业下的社区所有选项
  communityOption: state => {
    return state.community
  }
}

const mutations = {
  saveBrandClassifyOption(state, brandClassifyOption) {
    state.brandClassify = brandClassifyOption
  },
  saveBrandAllClassifyOption(state, brandAllClassifyOption) {
    state.brandAllClassify = brandAllClassifyOption
  },
  saveCommunityOption(state, communityOption) {
    state.community = communityOption
  }
}

const actions = {
  // 品牌分类 根据分类分组获取
  getBrandClassifyOption({commit, state}, refresh = false) {
    if(!state.brandClassifyOption || refresh) {
      api.post(url.brandClassify, {}).then((res) => {
        commit("saveBrandClassifyOption", res.data)
      })
    }
  },
  // 品牌分类 所有分类
  getBrandAllClassifyOption({commit, state}, refresh = false) {
    if(!state.brandAllClassifyOption || refresh) {
      api.post(url.brandAllClassify, {}).then((res) => {
        commit("saveBrandAllClassifyOption", res.data)
      })
    }
  },
  // 绑定物业下的社区所有选项
  getCommunityOption({commit, state}, refresh = false) {
    if(!state.communityOption || refresh) {
      api.get(url.communityUrl, {}).then((res) => {
        commit("saveCommunityOption", res.data)
      })
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
